import { useMemo } from "react";
import styles from "./CardComponent.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const CardComponent = ({
  img,
  titleComponent,
  propLeft,
  propWidth,
  kajCastIronManholeCoverLeft,
  propHeight,
  propTop,
  frame11Position,
  frame11Height,
  frame11Top,
  frame11Left,
  frame11Width,
  kajCastIronManholeCoverTop,
  frame11BackgroundColor,
  frame11BoxShadow,
}) => {
  const iconStyle = useMemo(() => {
    return {
      width: propWidth,
      height: propHeight,
    };
  }, [propWidth, propHeight]);

  const kajCastIronStyle = useMemo(() => {
    return {
      left: kajCastIronManholeCoverLeft,
      top: kajCastIronManholeCoverTop,
    };
  }, [kajCastIronManholeCoverLeft, kajCastIronManholeCoverTop]);

  const frameDivStyle = useMemo(() => {
    return {
      position: frame11Position,
      height: frame11Height,
      top: frame11Top,
      left: frame11Left,
      width: frame11Width,
      backgroundColor: frame11BackgroundColor,
      boxShadow: frame11BoxShadow,
    };
  }, [
    frame11Position,
    frame11Height,
    frame11Top,
    frame11Left,
    frame11Width,
    frame11BackgroundColor,
    frame11BoxShadow,
  ]);

  return (
    <div className={styles.parent} style={frameDivStyle}>
      <div className= {styles.frame}>
        <LazyLoadImage className={styles.icon} alt="" src={img} style={iconStyle} />
      </div>
    </div>
  );
};

export default CardComponent;
