import SectionHeading from "../components/SectionHeading";
import Divider from "../components/Divider";
import CardComponent from "../components/CardComponent";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./Products.module.css";
import React, { useState, useEffect } from 'react'
import { LazyLoadImage } from "react-lazy-load-image-component";


const Products = () => {
  const [width, setWindowWidth] = useState(0)
  useEffect(() => { 

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => 
      window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
    }

    const setPropWidth = (width1,width2, width3, width4) => {
      if (width > 1180) {
        return width1;
      }
      else if (width > 840 && width < 1180) {
        return width2;
      } else if (width > 680 && width < 840) {
        return width3;
      } else {
        return width4;
      }
    }

    const setPropHeight = (height1,height2, height3, height4) => {
      if (width > 1180) {
        return height1;
      }
      else if (width > 840 && width < 1180) {
        return height2;
      } else if (width > 680 && width < 840) {
        return height3;
      } else {
        return height4;
      }
    }
  return (
    <>
    <div className={styles.products}>
      <Navbar />
      <div className={styles.heroSection}>
        <div
          className={styles.xm1Icon}
        >
        <h1 className={styles.productsHeading}>PRODUCTS</h1>
        </div>
      </div>
      <img
        className={styles.pageMarginIcon}
        alt=""
        id="Line4"
        src="/line-4.svg"
      />
      <div className={styles.productsSect}>
        <div className={styles.ironManholeCover}>
          <div className = {styles.SectionHeading}>
            <SectionHeading headingName="Cast Iron Manhole Covers and Frames" />
            <Divider />
          </div>
          <div className={styles.frame}>
              <CardComponent
                img="/ManholeCover3.png"
                propWidth = {setPropWidth("", "18rem", "11.5rem", "10.5rem")}
                propHeight= {setPropHeight("", "18rem", "12.5rem", "10.5rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover4.png"
                propWidth = {setPropWidth("", "23rem", "14.5rem", "12.5rem")}
                propHeight= {setPropHeight("", "18rem", "13.5rem", "11.5rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover11.png"
                propWidth = {setPropWidth("", "17rem", "12.5rem", "10.3rem")}
                propHeight= {setPropHeight("", "24rem", "12.5rem", "11rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover5.png"
                propWidth = {setPropWidth("26rem", "20rem", "15.5rem", "12.5rem")}
                propHeight= {setPropHeight("20rem", "15rem", "12.5rem", "9.3rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover6.png"
                propWidth = {setPropWidth("", "25rem", "14.5rem")}
                propHeight= {setPropHeight("", "18rem", "10.5rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover7.png"
                propWidth = {setPropWidth("26rem", "20rem", "12.5rem")}
                propHeight= {setPropHeight("19rem", "14rem", "9.5rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover8.png"
                propWidth = {setPropWidth("29rem", "22rem", "14.5rem")}
                propHeight= {setPropHeight("22rem", "17rem", "11.5rem")}

                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover9.png"
                propWidth = {setPropWidth("", "20rem", "12.5rem")}
                propHeight= {setPropHeight("", "20rem", "12.5rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover10.png"
                propWidth = {setPropWidth("26rem", "21rem", "16.5rem", "")}
                propHeight= {setPropHeight("20rem", "17rem", "11.5rem", "")}

                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/ManholeCover1.png"
                propWidth = {setPropWidth("20rem", "18rem", "12.5rem", "10.5rem")}
                propHeight= {setPropHeight("", "18rem", "12.5rem", "10.5rem")}
              />
              <CardComponent
                img="/ManholeCover2.png"
                propWidth = {setPropWidth("", "17rem", "12.5rem", "10.3rem")}
                propHeight= {setPropHeight("", "24rem", "12.5rem", "11rem")}
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              
          </div>
        </div>
        <div className={styles.ironGrating}>
          <div className = {styles.SectionHeading}>
            <SectionHeading
              headingName="Cast Iron Gratings and Frames"
            />
            <Divider />
          </div>
          <div className= {styles.frame}>
                <CardComponent
                  img="/CastIron1.png"
                  propWidth = {setPropWidth("26rem", "21rem", "15.5rem", "12rem")}
                  propHeight= {setPropHeight("17rem", "13rem", "9.5rem", "8rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron2.png"
                  propWidth = {setPropWidth("27rem", "22rem", "15.5rem")}
                  propHeight= {setPropHeight("17rem", "14rem", "9.5rem")}
                  frame11Height="360px"
                  frame11Width="302px"
                  // frame11BackgroundColor="#f26422"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron3.png"
                  propWidth = {setPropWidth("27rem", "22rem", "15.5rem")}
                  propHeight= {setPropHeight("18.5rem", "15rem", "10.5rem")}
                  frame11Height="360px"
                  frame11Width="302px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron4.png"
                  propWidth = {setPropWidth("", "18rem", "12.5rem")}
                  propHeight= {setPropHeight("", "18rem", "12.5rem", "10rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron5.png"
                  propWidth = {setPropWidth("", "18rem", "12.5rem")}
                  propHeight= {setPropHeight("", "18rem", "12.5rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron6.png"
                  propWidth = {setPropWidth("26rem", "22rem", "16rem")}
                  propHeight= {setPropHeight("17rem", "14rem", "10rem", "8rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron7.png"
                  propWidth = {setPropWidth("23rem", "20rem", "15.5rem")}
                  propHeight= {setPropHeight("20rem", "18rem", "12.5rem", "10rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
                <CardComponent
                  img="/CastIron8.png"
                  propWidth = {setPropWidth("", "18rem", "12.5rem")}
                  propHeight= {setPropHeight("", "18rem", "12.5rem")}
                  frame11Height="360px"
                  frame11Width="303px"
                  frame11BackgroundColor="#e4e4e4"
                  frame11BoxShadow="unset"
                />
            </div>
        </div>
        <div className={styles.decorativeGrating}>
          <div className= {styles.SectionHeading}>
            <SectionHeading
              headingName="Surface Boxes"
            />
            <Divider />
          </div>
          <div className= {styles.frame} >
            <CardComponent
              img="SurfaceBox1.png"
              propWidth = {setPropWidth("16rem", "14rem", "10rem", "8rem")}
              propHeight= {setPropHeight("20rem", "17rem", "12rem", "9.5rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/SurfaceBox2.png"
              propWidth = {setPropWidth("", "18rem", "12.5rem")}
              propHeight= {setPropHeight("", "18rem", "12.5rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/SurfaceBox3.png"
              propWidth = {setPropWidth("", "18rem", "12.5rem", "9.3rem")}
              propHeight= {setPropHeight("", "18rem", "12.5rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/SurfaceBox4.png"
              propWidth = {setPropWidth("", "18rem", "12.5rem")}
              propHeight= {setPropHeight("", "18rem", "12.5rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
          </div>
        </div>
        <div className={styles.agriculturalGrating}>
          <div className= {styles.SectionHeading}>
            <SectionHeading
              headingName="Agricultural Castings"
            />
            <Divider />
          </div>
            <div className = {styles.frame} >
              <CardComponent
                img="/AgriculturalCastings1.png"
                propWidth = {setPropWidth("", "18rem", "12.5rem", "9.3rem")}
                propHeight= {setPropHeight("", "18rem", "12.5rem")}
                frame11Height="360px"
                frame11Width="303px"
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/AgriculturalCastings2.png"
                propWidth = {setPropWidth("22rem", "21rem", "14rem")}
                propHeight= {setPropHeight("18rem", "17rem", "11rem")}
                frame11Height="360px"
                frame11Width="303px"
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
              <CardComponent
                img="/AgriculturalCastings3.png"
                propWidth = {setPropWidth("", "20rem", "12.5rem")}
                propHeight= {setPropHeight("24rem", "20rem", "12.5rem")}
                prop1Height= "24rem"
                frame11Height="360px"
                frame11Width="303px"
                frame11BackgroundColor="#e4e4e4"
                frame11BoxShadow="unset"
              />
            </div>
        </div>
        <div className={styles.ductileIronCasting}>
          <div className= {styles.SectionHeading} >
            <SectionHeading
              headingName="Valve Boxes"
            />
            <Divider />
          </div>
          <div className= {styles.frame}>
            <CardComponent
              img="/ValveBox1.png"
              propWidth = {setPropWidth("10rem", "8rem", "6rem", "4rem")}
              propHeight= {setPropHeight("24rem", "19rem", "15rem","11rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/ValveBox2.png"
              propWidth = {setPropWidth("14rem", "12rem", "9rem", "7rem")}
              propHeight= {setPropHeight("26rem", "22rem", "17rem", "13rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/ValveBox3.png"
              propWidth = {setPropWidth("10rem", "8rem", "7rem", "6rem")}
              propHeight= {setPropHeight("24rem", "20rem", "18rem", "15rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
          </div>
        </div>
        <div className={styles.MeterBoxes}>
          <div className= {styles.SectionHeading} >
            <SectionHeading
              headingName="Meter Boxes"
            />
            <Divider />
          </div>
          <div className= {styles.frame}>
            <CardComponent
              img="/MeterBox1.png"
              propWidth = {setPropWidth("25rem", "18rem", "12.5rem")}
              propHeight= {setPropHeight("24rem", "18rem", "12.5rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/MeterBox2.png"
              propWidth = {setPropWidth("24rem", "18rem", "12.5rem")}
              propHeight= {setPropHeight("26rem", "19rem", "12.5rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            {/* <CardComponent
              img="/MeterBox3.png"
              propWidth="28rem"
              propHeight="24rem"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            /> */}
          </div>
        </div>
        <div className={styles.Counterweights}>
          <div className= {styles.SectionHeading} >
            <SectionHeading
              headingName="Counterweights"
            />
            <Divider />
          </div>
          <div className= {styles.frame}>
            <CardComponent
              img="/Counterweights1.png"
              propWidth = {setPropWidth("20rem", "18rem", "12rem", "9.5rem")}
              propHeight= {setPropHeight("24rem", "21rem", "13rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/Counterweights2.png"
              propWidth = {setPropWidth("20rem", "18rem", "12rem", "9.5rem")}
              propHeight= {setPropHeight("24rem", "21rem", "13rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/Counterweights3.png"
              propWidth = {setPropWidth("24rem", "18rem", "13rem", "10rem")}
              propHeight= {setPropHeight("22rem", "17rem", "12rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/Counterweights4.png"
              propWidth = {setPropWidth("28rem", "20rem", "14rem")}
              propHeight= {setPropHeight("24rem", "18rem", "13rem")}
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
          </div>
        </div>
        <div className={styles.other}>
          <div className= {styles.SectionHeading}>
            <SectionHeading
              headingName="Other Products"
            />
            <Divider />
          </div>
          <div className= {styles.frame}>
            
              <CardComponent
              img="/13@2x.png"
              titleComponent="Recessed Cast Iron Manhole Cover"
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/14@2x.png"
              titleComponent="Kaj Cast Iron Manhole Cover"
              frame11Height="360px"
              frame11Width="302px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/16@2x.png"
              titleComponent="Kaj Cast Iron Manhole Cover"             
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />

            <CardComponent
              img="/18@2x.png"
              titleComponent="Cast Iron Valve Box"
              propWidth = {setPropWidth("22.2rem", "18rem", "12rem", "9.5rem")}
              propHeight= {setPropHeight("", "18rem", "12rem")}
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#fff"
              frame11BoxShadow="0px 0px 32px rgba(0, 0, 0, 0.15)"
            />
              
            <CardComponent
              img="/17@2x.png"
              titleComponent="Plastic Manhole Cover"
              frame11Height="360px"
              frame11Width="302px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
            <CardComponent
              img="/15@2x.png"
              titleComponent="Cast Iron Meter Box"
              frame11Height="360px"
              frame11Width="303px"
              frame11BackgroundColor="#e4e4e4"
              frame11BoxShadow="unset"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default Products;
