import { useMemo } from "react";
import styles from "./Divider.module.css";
const Divider = ({ dividerLeft }) => {
  const dividerStyle = useMemo(() => {
    return {
      left: dividerLeft,
    };
  }, [dividerLeft]);

  return <div className={styles.divider} style={dividerStyle} />;
};

export default Divider;
