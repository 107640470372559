import styles from "./FormMessage.module.css";
import emailjs from '@emailjs/browser';
import { on } from "events";
import React, {useRef} from "react";
const FormMessage = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a3qa2td', 'template_xmph5o1', form.current, '16z0VAP34i7hYmg4G')
      .then((result) => {
          console.log(result.text);
          alert("Message Sent");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
          e.target.reset();
      });
      
  };

  return (
    <div className={styles.formSection}>
      <div className={styles.formSectionContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.orangeBox1} />
          <div className={styles.heading}>SEND US A MESSAGE</div>
        </div>
        <div className={styles.nameParent}>

        {/* ------------------------------------------ */}
        <form action="#" ref = {form} onSubmit={sendEmail} autoComplete= {on}>
          <div className={styles.formRow}>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="name">Name</label>
              <div></div>
              <input id="name" type="text" className={styles.inputText} name = "name" placeholder="Name" required />
            </div>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="company_name">Company Name</label>
              <div></div>
              <input id="company_name" type="text" className={styles.inputText} name = "company_name" placeholder="Company name" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.inputData}>
                <label className={styles.label} htmlFor="email">Email</label>
                <div ></div>
                <input id="email" type="text" className={styles.inputText} name = "email" placeholder="Email" required />
            </div>
            <div className={styles.inputData}>
                <label className={styles.label} htmlFor="number">Phone Number</label>
                <div></div>
                <input id="number" type="text" className={styles.inputText} name = "number" placeholder="Ph. Number" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="message">Details</label>
              <div></div>
              <textarea id="message" className = {styles.textarea} rows="8" cols="65" name = "message" placeholder="Details" required></textarea>
            </div>
          </div>
          <input
            className={styles.button}
            type="submit"
            value= "Submit"
          />
        </form>
        {/* ------------------------------------------ */}
          
          
        </div>
      </div>
      <div className={styles.mapContainer}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d576.8715366160455!2d88.33268215030373!3d22.58321851922218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDM0JzU5LjEiTiA4OMKwMTknNTguNSJF!5e0!3m2!1sen!2sin!4v1688631031296!5m2!1sen!2sin"
          width="500" 
          height="500" 
          style= {{border: "0"}}
          className= {styles.mapImg} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d576.8715366160455!2d88.33268215030373!3d22.58321851922218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDM0JzU5LjEiTiA4OMKwMTknNTguNSJF!5e0!3m2!1sen!2sin!4v1688631031296!5m2!1sen!2sin"
          width="500" 
          height="500" 
          style= {{border: "0"}}
          className= {styles.mapImgMobile} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
        
      </div>
    </div>
  );
};

export default FormMessage;
