import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./BlogPost.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const BlogPost = () => {
  const navigate = useNavigate();

  const onInstaIconClick = useCallback(() => {
    window.open("/");
  }, []);

  const onlinkedinIconClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/kesar-iron-&-engineering-works-p-ltd/");
  }, []);

  const onFacebookClick = useCallback(() => {
    window.open("https://www.facebook.com/profile.php?id=100091896574985");
  }, []);

  const onTwitterIconClick = useCallback(() => {
    window.open("/");
  }, []);

  const onBlogPostClick = useCallback(() => {
    navigate("/blog-description");
  }, [navigate]);


  return (
    <>
      <Navbar color= "black" backgroundColor= "white"/>
      <div className={styles.blogPost}>
        <div className={styles.socialsContainer}>
            <LazyLoadImage
              className={styles.instaIcon}
              alt=""
              src="/phinstagramlogofill.svg"
            />
            <LazyLoadImage className={styles.linkedinIcon} alt="" src="/mdilinkedin.svg" onClick={onlinkedinIconClick} />
            <LazyLoadImage
              className={styles.facebookIcon}
              alt=""
              src="/icbaselinefacebook2.svg"
              onClick={onFacebookClick}
            />
            <LazyLoadImage className={styles.twitterIcon} alt="" src="/mditwitter.svg" />
          </div>
          <div className={styles.frameParent}>
            <div className={styles.container}>
              <div className= {styles.imgParent}>
                <LazyLoadImage
                  className={styles.Img2}
                  alt=""
                  src="/pexelsjacekjanskorupski5630409@2x.jpg"
                />
                <div className={styles.orangeBox2} />
              </div>
              <div className={styles.textParent2} onClick={onBlogPostClick}>
                <div className={styles.Text2}>
                  The Top Considerations for Choosing Quality Covers
                </div>
                <LazyLoadImage className={styles.Arrow2} alt="" src="/arrow-1.svg" />
              </div>
            </div>
            <div className={styles.container1}>
              <div className= {styles.textParent1} onClick={onBlogPostClick}>
                <div className={styles.Text1}>
                  Other Topics You’d Like To Add
                </div>
                <LazyLoadImage className={styles.Arrow1} alt="" src="/arrow-2.svg" />
              </div>
              <div className = {styles.imgParent}>
                <div className={styles.orangeBox1} />
                <LazyLoadImage
                  className={styles.Img1}
                  alt=""
                  src="/pexelsplatoterentev5828274@2x.png"
                />
              </div>
            </div>
            <div className={styles.container2}>
              <div className = {styles.imgParent}>
                <LazyLoadImage
                  className={styles.Img}
                  alt=""
                  src="/pexelskehnhermano3881034@2x.png"
                />
                <div className={styles.orangeBox} />
              </div>
              <div className= {styles.textParent} onClick={onBlogPostClick}>
                <div className={styles.Text}>
                  Other Topics You’d Like To Add
                </div>
                <LazyLoadImage className={styles.Arrow} alt="" src="/arrow-3.svg" />
              </div>
            </div>
          </div>
        <Footer />
      </div>  
    </>
  );
};

export default BlogPost;
