import styles from "./MunicipalContainer.module.css";
const MunicipalContainer = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        <div className={styles.municipalWrapper}>
          <div className={styles.municipal}>Municipal</div>
        </div>
        <div className={styles.railwaysWrapper}>
          <div className={styles.municipal}>Railways</div>
        </div>
      </div>
      <div className={styles.constructionWrapper}>
        <div
          className={styles.municipal}
        >{`Construction & Material Handling`}</div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.waterWrapper}>
          <div className={styles.municipal}>Waterworks</div>
        </div>
        <div className={styles.automotiveWrapper}>
          <div className={styles.municipal}>Automotive</div>
        </div>
      </div>
      <div className={styles.frameGroup}>
        <div className={styles.agriculuralWrapper}>
          <div className={styles.municipal}>Agriculural</div>
        </div>
        <div className={styles.hydraulicsWrapper}>
          <div className={styles.municipal}>Hydraulics</div>
        </div>
      </div>
    </div>
  );
};

export default MunicipalContainer;
