import {
  HashRouter,
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Router,
} from "react-router-dom";
import Products from "./pages/Products";
import AboutUs from "./pages/AboutUs";
import BlogDescription from "./pages/BlogDescription";
import BlogPost from "./pages/BlogPost";
import ContactUs from "./pages/ContactUs";
import Homepage from "./pages/Homepage";
import { useEffect } from "react";


function App() {

  // useEffect(() => {
  //   if (action !== "POP") {
  //     window.scrollTo(0, 0);
  //   }
  // }, [action, pathname]);

  // useEffect(() => {
  //   let title = "";
  //   let metaDescription = "";

  //   switch (pathname) {
  //     case "/":
  //       title = "";
  //       metaDescription = "";
  //       break;
  //   }

  //   if (title) {
  //     document.title = title;
  //   }

  //   if (metaDescription) {
  //     const metaDescriptionTag = document.querySelector(
  //       'head > meta[name="description"]'
  //     );
  //     if (metaDescriptionTag) {
  //       metaDescriptionTag.content = metaDescription;
  //     }
  //   }
  // }, [pathname]);

  return (
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="products" element={<Products />} />
          <Route path="blog-description" element={<BlogDescription />} />
          <Route path="blog-post" element={<BlogPost />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Routes>
      </HashRouter>
  );
}

export default App;
