import styles from "./QuickEnquiryForm.module.css";
import emailjs from '@emailjs/browser';
import React, {useRef} from "react";
const QuickEnquiryForm = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_a3qa2td', 'template_xmph5o1', form.current, '16z0VAP34i7hYmg4G')
      .then((result) => {
          console.log(result.text);
          alert("Message Sent");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className={styles.frameParent}>
      <div className={styles.headingParent}>
        <div className={styles.orangeBox} />
        <div className={styles.quickEnquiry}>Quick Enquiry</div>
      </div>
      <div className={styles.nameParent}>

        {/* ------------------------------------------ */}
        <form ref={form} onSubmit={sendEmail} autoComplete= "on">
          <div className={styles.formRow}>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="name">Name</label>
              <div></div>
              <input type="text" name = "name" className={styles.inputText} placeholder="Name" required />
            </div>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="">Company Name</label>
              <div></div>
              <input type="text" name = "company_name" className={styles.inputText} placeholder="Company name" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.inputData}>
                <label className={styles.label} htmlFor="">Email</label>
                <div ></div>
                <input type="text" name = "email" className={styles.inputText} placeholder="Email" required />
            </div>
            <div className={styles.inputData}>
                <label className={styles.label} htmlFor="">Phone Number</label>
                <div></div>
                <input type="text" name = "number" className={styles.inputText} placeholder="Ph. Number" required />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.inputData}>
              <label className={styles.label} htmlFor="">Details</label>
              <textarea name = "message" className = {styles.textarea}rows="8" cols="65" required placeholder="Details"></textarea>
            </div>
          </div>
          <input
            className={styles.button}
            value= "Submit"
            type="submit"
          />
        </form>
        {/* ------------------------------------------ */}
        </div>
    </div>
  );
};

export default QuickEnquiryForm;
