import Navbar from "../components/Navbar";
import ContactContainer from "../components/ContactContainer";
import FormMessage from "../components/FormMessage";
import Footer from "../components/Footer";
import styles from "./ContactUs.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ContactUs = () => {
  


  return (
    <div className={styles.contactUs}>
      <Navbar backgroundColor="white" color="black" position= "relative"/>
      <div className={styles.contactUsPage}>
        <div className={styles.contactUsSection}>
          <ContactContainer />
          <LazyLoadImage
            className={styles.Img}
            alt=""
            src="/downloader-1@2x.jpg"
          />
        </div>
        <FormMessage />
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
