import BlogText from "../components/BlogText";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./BlogDescription.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const BlogDescription = () => {
  
  
  return (
    <>
    <div className={styles.blogDescription}>
      <Navbar isWhite={true} />
      
      <div className={styles.heroSection}>
        <div
          className={styles.bgImg}
        >
          <h1 className={styles.pageHeading}>BLOG</h1>
        </div>
      </div>
      <LazyLoadImage
        className={styles.Img1}
        alt=""
        src="/pencilsketchadjusted2532102-1@2x.png"
      />
      <LazyLoadImage
        className={styles.Img2}
        alt=""
        src="/pencilsketchadjusted5037286-2@2x.png"
      />
      <div className={styles.postParent}>
        <div className={styles.post1}>
          <div className={styles.post1Child} />
          <div className={styles.postHeadingContainer}>
            <p className={styles.postHeading}>
              The Top Considerations for Choosing Quality Covers
            </p>
          </div>
          <div className={styles.post1Wrapper}>
            <div className={styles.postContent}>
              <p
                className={styles.postHeading}
              >{`Manhole openings, whether at home or in commercial spaces, must be properly covered to avoid issues or discomfort. While the selection of specialized drain covers may be limited, recessed drain covers from leading manufacturers like Kesar Iron & Engineering Works Pvt Ltd are a reliable option to consider. Users of these covers have generally been satisfied with their choice. However, it is also worth considering ductile iron manhole covers in India as an alternative. When purchasing these covers for the first time, there are several important factors to consider to ensure you are getting the best quality product.`}</p>
              <p className={styles.postHeading}>&nbsp;</p>
              <p className={styles.postHeading}>
                First and foremost, the material used in the construction of the
                cover should be of high quality. Both cast iron and ductile iron
                are durable options that offer superior usability. In many
                cases, manufacturers produce these materials themselves to
                ensure the highest level of quality. Additionally, choosing a
                cover that is made with quality materials will help it to last
                longer, making it a more worthwhile investment.
              </p>
              <p className={styles.postHeading}>&nbsp;</p>
              <p
                className={styles.postHeading}
              >{`It is also important to speak with experts and read reviews from both online and offline sources to get a well-rounded understanding of the best options available. Stay neutral and consider all reviews carefully, paying particular attention to genuine Google reviews. By following these steps and taking the time to research your options, you can find the best recessed drain covers or cast iron manhole covers in India that offer the best value for your money. For additional guidance, you may also want to reach out to the experts at Kesar Iron & Engineering Works Pvt Ltd.`}</p>
            </div>
          </div>
        </div>
        <div className={styles.post2}>
          <div className={styles.post2Child} />
          <div className={styles.postHeadingContainer}>
            <p className={styles.postHeading}>Grey Cast Iron</p>
          </div>
          <div className={styles.post2Subhead}>
            The Timeless Material of Choice for Durability and Reliability
          </div>
          <BlogText />
        </div>
      </div>
      <Footer
      />
    </div>
    </>
  );
};

export default BlogDescription;
