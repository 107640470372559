import styles from "./ContactContainer.module.css";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";




const ContactContainer = () => {
  const navigate = useNavigate();

  const onInstaClick = useCallback(() => {
    window.open("/");
  }, [navigate]);

  const onLinkedinClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/kesar-iron-&-engineering-works-p-ltd/about/");
  }, [navigate]);

  const onFacebookClick = useCallback(() => {
    window.open("https://www.facebook.com/profile.php?id=100091896574985");
  }, [navigate]);

  const onTwitterClick = useCallback(() => {
    window.open("/");
  }, [navigate]);

  return (
    <div className={styles.contactUsParent}>
      <div className={styles.contactUsHeading}>CONTACT US</div>
      <LazyLoadImage
            className={styles.Img}
            alt=""
            src="/downloader-1@2x.jpg"
            onClick={onInstaClick}
          />
      <div className={styles.socials}>
        <img
          className={styles.instaIcon}
          alt=""
          src="/phinstagramlogofill.svg"
          onClick={onInstaClick}
        />
        <img className={styles.linkedinIcon} alt="" src="/mdilinkedin.svg" onClick={onLinkedinClick}/>
        <img
          className={styles.facebookIcon}
          alt=""
          src="/icbaselinefacebook2.svg"
          onClick={onFacebookClick}
        />
        <img className={styles.twitterIcon} alt="" src="/mditwitter.svg" onClick={onTwitterClick} />
      </div>
      <div className={styles.companyDetails}>
            <div className={styles.iconGroup}>
              <img className={styles.locationIcon} alt="" src="/vector32.svg" />
              <div className={styles.address}>
              18, G.T Road 4th Floor Howrah 711101 (Office)
              </div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.locationIcon} alt="" src="/vector32.svg" />
              <div className={styles.address}>
              58, NS Road Liluah Howrah 711204 (Works)
              </div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.callIcon} alt="" src="/vector2.svg" />
              <div className={styles.address}>+91-9163220444</div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.phoneIcon} alt="" src="/vector2.svg" />
              <div className={styles.address}>+91-33-26411257</div>
            </div>
            <div className={styles.iconGroup}>
              <img className={styles.mailIcon} alt="" src="/vector3.svg" />
              <div className={styles.address}>contact@kesariron.in</div>
            </div>
          </div>
    </div>
  );
};

export default ContactContainer;
