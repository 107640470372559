import { useCallback } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
import {FaBars, FaTimes} from "react-icons/fa";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Navbar = ({backgroundColor, color, position, isWhite}) => {
  const [clicked, setClicked] = useState(false)
  const navigate = useNavigate();

  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProductsClick = useCallback(() => {
    navigate("/products");
  }, [navigate]);

  const onBlogClick = useCallback(() => {
    navigate("/blog-post");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onContactUsClick = useCallback(() => {
    navigate("/contact-us");
  }, [navigate]);

  const handleClick = () => {
    setClicked(!clicked)
  }

  const colorStyle = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
      color: color,
      position: position
    };
  }, [
    color,
    backgroundColor,
    position
  ]);


  return (
    <div className={styles.header} style={colorStyle}>
      <LazyLoadImage
        className= {styles.whiteLogo}
        alt=""
        id="NavLogo"
        src= {isWhite ? "/logoWhite.png": "/LogoBlack.png"}
        key= "/LogoBlack"
        onClick={onLogoClick}
      />
      <div className={`${styles.componentParent} ${clicked ? styles.active: styles.inactive}`} id="NavItems">
        <div className={styles.component6} onClick={onHomeClick}>
            <p className={styles.home1}>Home</p>
        </div>
        <div className={styles.component10} onClick={onProductsClick}>
            <p className={styles.home1}>Products</p>
        </div>
        <div className={styles.component6} onClick={onBlogClick}>
            <p className={styles.home1}>Blog</p>
        </div>
        <div className={styles.component8} onClick={onAboutUsClick}>
            <p className={styles.home1}>About Us</p>
        </div>
        <div className={styles.component9} onClick={onContactUsClick}>
            <p className={styles.home1}>Contact Us</p>
        </div>
        <div className={styles.icroundSearch}>
          <img className={styles.vectorIcon} alt="" src="/vector.svg" />
        </div>
      </div>
      <div className= {styles.mobile} onClick={handleClick}>
        <i id = "bar" className= {clicked ? "fas fa-times" : "fas fa-bars"} />
      </div>
    </div>
  );
};

export default Navbar;
