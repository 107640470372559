import { useMemo } from "react";
import styles from "./SectionHeading.module.css";
const SectionHeading = ({ headingName, headingLeft, headingWidth }) => {
  const headingStyle = useMemo(() => {
    return {
      left: headingLeft,
      width: headingWidth,
    };
  }, [headingLeft, headingWidth]);

  return (
    <b className={styles.heading} style={headingStyle}>
      {headingName}
    </b>
  );
};

export default SectionHeading;
