import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import QuickEnquiryForm from "../components/QuickEnquiryForm";
import Footer from "../components/Footer";
import styles from "./Homepage.module.css";
import homeHeroVid from "../assets/homeHeroVid.mp4"
import homepageHero from "../assets/homepageHero.svg"
import FormMessage from "../components/FormMessage";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Homepage = () => {
  const navigate = useNavigate();

  const onViewProductsClick = useCallback(() => {
    navigate("/products");
  }, [navigate]);

  return (
    <div className={styles.homepage}>
      <Navbar isWhite={true} />
      <div className={styles.heroSection}>
        <video src= {homeHeroVid} loop muted autoPlay playsInline poster= {homepageHero} className={styles.backgroundVideo}>
          
        </video>
        <div
          className={styles.pageHeading}
        >{`RENOWNED MANUFACTURER & EXPORTER OF CAST IRON PRODUCTS`}</div>
      </div>
      <div className={styles.envelopeContainer}>
        <LazyLoadImage
          className={styles.envelopeIcon}
          alt=""
          src="/uilenvelopes.svg"
        />
        <div className={styles.envelopeText}>Send a message</div>
      </div>
      <div className={styles.content}>
        <div className={styles.section1}>
          <div className={styles.section1HeadingContainer}>
            <div className={styles.orangeBox} />
            <div className={styles.heading}>
              <p className={styles.our}>OUR</p>
              <p className={styles.our}>{`MISSION & VISION`}</p>
            </div>
          </div>
          <div className={styles.section1Content}>
            <div className= {styles.section1Group}>
              <LazyLoadImage className={styles.Img1} alt="" src="/group-9.svg" />
              <div className={styles.group1Text}>
                <span>{`A maximize value creation for all stakeholders through supply of `}</span>
                <span
                  className={styles.costEffectiveAndSuperior}
                >{`cost-effective and superior quality products `}</span>
                <span>in domestic and global markets.</span>
              </div>
            </div>
            <div className={styles.line} />
            <div className= {styles.section1Group}>
              <LazyLoadImage className={styles.Img2} alt="" src="/group-8.svg" />

              <div className={styles.group2Text}>
                <span>{`To strive for excellence in all our areas of business & `}</span>
                <span className={styles.costEffectiveAndSuperior}>
                  grow our markets globally
                </span>
                <span>
                  {" "}
                  while ensuring the growth of all stakeholders in the journey.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section2}>
          <div className={styles.gridItem1}>
            <div className= {styles.textContainer}>
              <LazyLoadImage
                className={styles.handImg}
                alt=""
                src="/untitled-design-4-1.svg"
              />
              <b className={styles.item1Text}>
                Our quality checks start from the raw materials that come to our
                factory which is tested rigorously in certified laboratories.
              </b>
            </div>
          </div>            
            <div className={styles.gridItem2}>
              <div className={styles.group1}>
                <LazyLoadImage className={styles.icon1} alt="" src="/ManholeCover6.png" />
                <b className={styles.roundIronGrill}>
                  Cast Iron Manhole Cover
                </b>
              </div>
              <div className={styles.group2}>
                <LazyLoadImage className={styles.icon2} alt="" src="/MeterBox1.png" />
                <b className={styles.roundIronGrill}>Meter Box</b>
              </div>
              
              <div className={styles.group3}>
                <LazyLoadImage
                  className={styles.youtubeLogo}
                  alt=""
                  src="/logosyoutubeicon.svg"
                />
              </div>
              <div className={styles.group4}>
                <LazyLoadImage className={styles.icon3} alt="" src="/CastIron1.png" />
                <b className={styles.roundIronGrill}>Cast Iron Gratings</b>
              </div> 
            </div>
            <div className={styles.gridItem2}>
              <div className={styles.group5}>
                <LazyLoadImage className={styles.icon5} alt="" src="/SurfaceBox2.png" />
                <b className={styles.roundIronGrill}>
                  Surface Boxes
                </b>
              </div>
              <div className={styles.group6}>
                <button
                  className={styles.button}
                  onClick={onViewProductsClick}
                >
                  View Products
                </button>
              </div>
              <div className={styles.group7}>
                <LazyLoadImage className={styles.icon7} alt="" src="/AgriculturalCastings1.png" />
                <b className={styles.roundIronGrill}>Agricultural Castings</b>
              </div>
              <div className={styles.group8}>
                <LazyLoadImage className={styles.icon8} alt="" src="/Counterweights1.png" />
                <b className={styles.roundIronGrill}>
                  <p className={styles.our}>Counterweights</p>
                </b>
              </div>
            </div>
            <div className={styles.gridItem4}>
              <div className = {styles.textContainer}>
                  <LazyLoadImage
                    className={styles.kesarIronLogo}
                    alt=""
                    src="/kesar-iron-logo-1-1-1.svg"
                  />
                  <div className={styles.item4Container}>
                    <p className={styles.our}>
                      <span className={styles.item4Text}>
                        COMPLETE TURNKEY SOLUTIONS
                      </span>
                      <span> INSTEAD OF</span>
                    </p>
                    <p className={styles.our}>JUST INDIVIDUAL PRODUCTS</p>
                  </div>
              </div>
            </div>
        </div>
        <div className={styles.section3}>
          <b className={styles.para1}>
            <span>{`We are `}</span>
            <span
              className={styles.costEffectiveAndSuperior}
            >{`ISO 9001:2015 certified company & approved by NSIC, `}</span>
            <span>a Govt. of India concern.</span>
          </b>
          <div className={styles.para2}>
            <span>{`We are a SSI Unit & have credentials of supply & work from reputed companies in India. `}</span>
            <span
              className={styles.costEffectiveAndSuperior}
            >{`Our products follow up the international standard `}</span>
            <span>
              and catering the needs of different sectors which includ
            </span>
            <span className={styles.costEffectiveAndSuperior}>
              e Agriculture, Municipal, Telecom and Railways
            </span>
            <span> among others.</span>
          </div>
        </div>
        <QuickEnquiryForm />
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
