// import styles from "./BlogText.module.css";
import styles from "../pages/BlogDescription.module.css";
const BlogText = () => {
  return (
    <div className={styles.post2Wrapper}>
      <div className={styles.postContent}>
        <p className={styles.postHeading}>
          Grey cast iron belong to the oldest and most used materials for
          casting production. They have good foundry qualities and with
          continuous improvement of production technology they have achieved
          also suitable utility properties which have shown with increased of
          technical level and working reliability of castings.
        </p>
        <p className={styles.postHeading}>&nbsp;</p>
        <p className={styles.postHeading}>
          The risk of fire in a building with plastic sanitary products can not
          only result in repairs, but also full replacement due to their
          inadequate heat resistance. In contrast, grey cast iron products are
          highly fire-resistant and can effectively impede the spread of fires.
          Furthermore, burning plastic releases toxic fumes that can be
          dangerous to inhale, adding an additional layer of danger.
        </p>
        <p className={styles.postHeading}>&nbsp;</p>
        <p className={styles.postHeading}>
          Iron, as a material, boasts exceptional durability and longevity when
          compared to plastic. It is denser and less prone to breaking or
          deforming under rough handling, as commonly seen on construction
          sites. Grey cast iron, in particular, has been a trusted material in
          plumbing systems for centuries, with thousands of miles of municipal
          water and sewer systems relying on cast iron products for their
          long-term durability.
        </p>
        <p className={styles.postHeading}>&nbsp;</p>
        <p className={styles.postHeading}>
          While plastic products may have a lower upfront cost, the added cost
          of maintenance can surpass that of grey cast iron. Moreover, the need
          for frequent repairs is also reduced with cast iron, allowing for the
          money saved to be invested elsewhere.
        </p>
        <p className={styles.postHeading}>&nbsp;</p>
        <p className={styles.postHeading}>
          One of the most common complaints regarding plastic products is their
          tendency to produce a higher level of noise compared to cast iron. The
          thickness and density of cast iron products greatly reduces this
          noise, creating a more serene atmosphere. This is particularly
          important for homeowners who value a peaceful living space and want to
          avoid the constant noise of running faucets, showers, and toilets.
        </p>
        <p className={styles.postHeading}>&nbsp;</p>
        <p className={styles.postHeading}>
          In the interest of minimizing our impact on the environment, cast iron
          sanitary products are a more sustainable choice. Unlike plastic
          sanitary products, which are derived from oil-based products and
          contribute to waste when disposed of, cast iron sanitary products can
          be produced from scrap iron and can be recycled at the end of their
          lifespan.
        </p>
      </div>
    </div>
  );
};

export default BlogText;
