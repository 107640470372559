import MunicipalContainer from "../components/MunicipalContainer";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./AboutUs.module.css";
import heroImg from "../assets/hero-section1@3x.jpg";
import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
const AboutUs = () => {
  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const updateLogo = () => {
    if (width <= 680) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <Navbar isWhite={updateLogo()} />
      <div className={styles.aboutUs} id="sectors">
        <div className={styles.heroSection}>
          <div className={styles.pageHeading}>About Us</div>
        </div>
        <div className={styles.content}>
          <div className={styles.ourStory}>
            <div className={styles.headingParent}>
              <div className={styles.orangeBox} />
              <div className={styles.ourStoryHeading}>OUR STORY</div>
            </div>
            <div className={styles.ImgParentMobile}>
              <LazyLoadImage className={styles.Img} alt="" src={heroImg} />
              <div className={styles.orangeBox2} />
            </div>
            <div className={styles.ourStoryContainer}>
              <span>{`Established in the year 2006, we at Kesar Iron & Engineering Works Private Limited
are renowned `}</span>
              <span className={styles.text}>
                manufacturers and exporter of Cast iron manhole cover, Cast iron
                cleanout covers, Cast iron meter box/body, and Cast iron
                counterweight in Asia, Middle East, Europe and North America.
              </span>
              <span>{` We are `}</span>
              <span
                className={styles.text}
              >{`ISO 9001:2015 certified company & approved by NSIC, a Govt. of India concern. `}</span>
              <span>{` We are an
SSI Unit & have credentials of supply & work from reputed companies in India. Our
products follow up the international standards and catering the needs of different
sectors which include Agriculture, Municipal, Telecom and Railways among others.`}</span>
            </div>
          </div>
          <div className={styles.ImgParent}>
            <LazyLoadImage
              className={styles.Img}
              alt=""
              src="/downloader-2@2x.jpg"
            />
            <div className={styles.orangeBox2} />
          </div>
          <div className={styles.directorSection}>
            <div className={styles.textContainer}>
              <div className={styles.paragraph}>
                <span>{`It has always been our endless endeavor to try to get better the quality of our products. `}</span>
                <span
                  className={styles.text}
                >{`We are using the new technologies and products to answer the changing demand of the market and society. `}</span>
                <span>
                  We are offering complete turnkey solutions instead of just
                  selling individual products.
                  <a href="https://drive.google.com/drive/folders/1zfyu_70THe3W4nMjP6475xxPTiJJXobZ?usp=drive_link">Find out more.</a>
                </span>
              </div>
              <div className={styles.paragraph}>
                <span>{`Our high-tech infrastructure is laced with all the essential machinery and tool, which services the achievement of different company objectives and goals. `}</span>
                <b>
                  The facility is to follow up regular up-gradation, for keeping
                  the position in the market. To manage the firm’s operations in
                  the best possible manner, the facility has been parted into
                  several highly operational units.
                </b>
              </div>
              <div className={styles.paragraph}>
                <span>
                  Under the headship of our mentor Arvind (Founder), our
                  organization is growing quickly in this industry. He always
                  motivates his employees so that they can attain the goals of
                  the company.
                </span>
                <span className={styles.text}>
                  {" "}
                  Our products are manufactured under the leadership of
                  experienced professionals, which helps us attain greater
                  efficiency
                </span>
                <span>
                  . Additionally, the ethical work practices have assisted us to
                  generate a massive client base.
                </span>
              </div>
            </div>
            <div className={styles.gridContainer}>
              <div className={styles.gridItem1}>
                <div className={styles.Establishment}>
                  <p className={styles.textStyle}>{`Year of `}</p>
                  <p className={styles.textStyle}>Establishment</p>
                </div>
                <div className={styles.year}>
                  <span className={styles.span1}>20</span>
                  <span className={styles.span}>06</span>
                </div>
              </div>
              <div className={styles.orangeBox3} />
              <div className={styles.orangeBox3} />

              <div className={styles.gridItem4}>
                <div className={styles.directorName}>
                  <p className={styles.textStyle}>Arvind</p>
                  <p className={styles.textStyle}>Taparia</p>
                </div>
                <div className={styles.director}>Director</div>
              </div>
            </div>
          </div>
          <div className={styles.companyDetailsContainer}>
            <div className={styles.companyDetails}>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.locationIcon}
                  alt=""
                  src="/vector32.svg"
                />
                <div className={styles.address}>
                  18, G.T Road 4th Floor Howrah 711101 (Office)
                </div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.locationIcon}
                  alt=""
                  src="/vector32.svg"
                />
                <div className={styles.address}>
                  58, NS Road Liluah Howrah 711204 (Works)
                </div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.callIcon}
                  alt=""
                  src="/vector2.svg"
                />
                <div className={styles.address}>+91-9163220444</div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.phoneIcon}
                  alt=""
                  src="/vector2.svg"
                />
                <div className={styles.address}>+91-33-26411257</div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.mailIcon}
                  alt=""
                  src="/vector3.svg"
                />
                <div className={styles.address}>contact@kesariron.in</div>
              </div>
            </div>
            <div className={styles.frameParent1}>
              <div className={styles.gstNumberParent}>
                <div className={styles.Title}>
                  GST <span className={styles.full}>Number</span>
                  <span className={styles.short}>No.</span>
                </div>
                <div className={styles.gstNumber}>19AACCK8546L1ZX</div>
              </div>
              <div className={styles.businessContainer}>
                <div
                  className={styles.business}
                >{`Manufacturer, Exporter & Supplier`}</div>
              </div>
            </div>
          </div>
          <div className={styles.frameParent2}>
            <div className={styles.rectangleContainer}>
              <div className={styles.orangeBox4} />
              <div className={styles.targetSectors}>Target Sectors</div>
            </div>
            <MunicipalContainer />
            <div className={styles.companyDetailsMobile}>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.locationIcon}
                  alt=""
                  src="/vector32.svg"
                />
                <div className={styles.address}>
                  18, G.T Road 4th Floor Howrah 711101 (Office)
                </div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.locationIcon}
                  alt=""
                  src="/vector32.svg"
                />
                <div className={styles.address}>
                  58, NS Road Liluah Howrah 711204 (Works)
                </div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.callIcon}
                  alt=""
                  src="/vector2.svg"
                />
                <div className={styles.address}>+91-9163220444</div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.phoneIcon}
                  alt=""
                  src="/vector2.svg"
                />
                <div className={styles.address}>+91-33-26411257</div>
              </div>
              <div className={styles.iconGroup}>
                <LazyLoadImage
                  className={styles.mailIcon}
                  alt=""
                  src="/vector3.svg"
                />
                <div className={styles.address}>contact@kesariron.in</div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
