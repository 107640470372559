import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";
const Footer = () => {
  const navigate = useNavigate();

  const onHomeClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onProductsClick = useCallback(() => {
    navigate("/products");
  }, [navigate]);

  const onAboutUsClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onInstaClick = useCallback(() => {
    window.open("/");
  }, []);

  const onLinkedinClick = useCallback(() => {
    window.open("https://www.linkedin.com/company/kesar-iron-&-engineering-works-p-ltd/");
  }, []);

  const onFacebookClick = useCallback(() => {
    window.open("https://www.facebook.com/profile.php?id=100091896574985");
  }, []);

  const onTwitterClick = useCallback(() => {
    window.open("/");
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className= {styles.footerMenu}>
          <div className= {styles.logoContainer}>
            <img
              className={styles.logo}
              alt=""
              src="/12577compimage-1-4@2x.png"
            />
          </div>
          <div className={styles.quickLinksParent}>
            <div className={styles.quickLinks}>
              <div className={styles.heading}>Quick Links</div>
              <div className={styles.links}>
                <div className={styles.component}>
                    <a className={styles.home1} onClick={onHomeClick}>
                      Home
                    </a>
                </div>
                <div className={styles.component}>
                    <a className={styles.home1} onClick={onProductsClick}>
                      Products
                    </a>
                </div>
                <div className={styles.component}>
                    <a className={styles.home1} onClick={onAboutUsClick}>
                      About Us
                    </a>
                </div>
              </div>
            </div>
            <div className={styles.quickLinks}>
                <div className={styles.heading}>Contact Us</div>
                <div className={styles.frameParent}>
                  <div className={styles.iconParent}>
                    <img
                      className={styles.vectorIcon}
                      alt=""
                      src="/vector1.svg"
                    />
                    <img
                      className={styles.vectorIcon}
                      alt=""
                      src="/vector2.svg"
                    />
                    <img
                      className={styles.vectorIcon}
                      alt=""
                      src="/vector3.svg"
                    />
                  </div>
                  <div className={styles.parent}>
                    <div className={styles.contactContent}>+91-9163220444</div>
                    <div className={styles.contactContent}>+91-33-26411257</div>
                    <div className={styles.contactContent}>
                      contact@kesariron.in
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className={styles.socials}>
            <img
              className={styles.socialIcons}
              alt=""
              src="/vector4.svg"
              onClick={onInstaClick}
            />
            <img
              className={styles.socialIcons}
              alt=""
              src="/vector5.svg"
              onClick={onLinkedinClick}
            />
            <img
              className={styles.socialIcons}
              alt=""
              src="/vector6.svg"
              onClick={onFacebookClick}
            />
            <img
              className={styles.socialIcons}
              alt=""
              src="/vector7.svg"
              onClick={onTwitterClick}
            />
          </div>
        </div>
      <div className={styles.footerIgnore}>
          <div className={styles.privacyPolicy}>Privacy Policy</div>
        
          <div className={styles.copyright}>Copyright @ 2023</div>
          
        
      </div>
      </div>
    </div>
  );
};

export default Footer;
